@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  src: local('Inter Regular'),
    url('../public/assets/fonts/Inter-Light-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter Regular'), url('../public/assets/fonts/Inter-Bold.woff') format('woff');
}
:root {
  --control-color: #43AF01;
  --form-control-disabled: #959495;
}

* {
  padding: 0;
  margin: 0;
}
body {
  color: #ffffff;
  background: black;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  overflow: hidden;
  overflow-y: scroll;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
button {
  background-color: transparent;
  border: none;
  text-decoration: none;
  outline: none;
}
button:hover,
button:focus,
input:hover,
input:focus,
select:hover,
select:focus,
textarea:hover,
textarea:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: inherit;
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #ffffff;
  overflow-wrap: break-word;
}
p {
  margin: 0;
}
/* *:not(body) {
  scrollbar-width: thin;
  scrollbar-color: #ffffff;
}
*:not(body)::-webkit-scrollbar {
  width: 6px;
}
*:not(body)::-webkit-scrollbar-track {
  background: #ffffff;
}
*:not(body)::-webkit-scrollbar-thumb {
  background-color: #ffffff;
} */
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background: black;
  /* border-radius: 0 30px 30px 0; */
  transition: all 0.5s ease;
  color: #4CC800;
  border-right: 1px solid #4CC800;
  text-transform: uppercase;
  overflow-y: scroll;
}
nav.close {
  width: 60px;
}
.logo {
  content: url('../public/assets/images/penn-full-logo.png');
  width: 80%;
  margin-bottom: 20px;
  padding: 30px 20px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

}
.logo.close {
  content: url('../public/assets/images/Penn-icon.png');
  padding: 30px 5px;
}
nav ul {
  /* padding-left: 15px; */
  overflow-y: scroll;
}
nav.close ul {
  padding-left: 5px;
}
nav ul li {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 40px 0 0 40px;
  display: flex;
  align-items: center;
  font-size: 8px;
}
nav.close ul li {
  padding: 15px 0 15px 10px;
  border-radius: 30px 0 0 30px;
}
nav ul li:hover,
nav ul li.active {
  /* background: #FFC025; */
  -webkit-transition: 0.5 ease;
  -o-transition: 0.5 ease;
  transition: 0.5 ease;
}
nav ul li .icon {
  width: 40px;
  padding: 0px 10px;
}
nav ul li span {
  font-size: 10px;
  letter-spacing: 1.2px;
}
nav.close ul li span {
  display: none;
}
.page-container {
  display: inline-block;
  margin-left: 250px;
  width: calc(100vw - 250px);
  height: 100%;
}
.page-container.close {
  margin-left: 60px;
  width: calc(100vw - 60px);
}
.admin-header {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  position: relative;
  width: 100%;
  z-index: 99;
  -webkit-transition: 0.5 ease;
  -o-transition: 0.5 ease;
  transition: 0.5 ease;
  background: black;
}
.sticky {
  top: 0;
  background-color: #303030;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  position: sticky;
}
.menu-icon {
  position: relative;
  width: 24px;
  height: 22px;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.menu-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.menu-icon span:nth-child(1) {
  top: 2px;
}
.menu-icon span:nth-child(2),
.menu-icon span:nth-child(3) {
  top: 10px;
}
.menu-icon span:nth-child(4) {
  top: 18px;
}
.header-input {
 background: #171717;
  width: 550px;
  max-width: 60%;
  border: none;
  border-radius: 10px;
  padding: 10px 40px 10px 15px;
  margin-left: 45px;
  color: #fff;
}
.profile {
  position: relative;
  display: flex;
  margin-left: auto;
  align-items: center;
  color: #ffffff;
}
.profile img {
  width: 35px;
  margin-left: 5px;
}
.profile-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background: #515151;
  width: 180px;
  margin-top: 10px;
  z-index: 99;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.03);
}
.profile-dropdown li {
  padding: 10px 15px;
}
.profile-dropdown li i {
  margin-right: 10px;
}
.profile-dropdown li:hover {
  background: #303030;
}
.page-body {
  padding: 30px;
  background: black;
}
.page-body h1 {
  font-size: calc(24px + (32 - 24) * ((100vw - 300px) / 1300));
  font-weight: 600;
  margin-bottom: 25px;
}
.accordion-item {
  background-color: transparent;
  margin-bottom: 25px;
}
.accordion-button {
  background: #515151;
  color: #ffffff;
}
.info-box {
  position: relative;
  background: #202020;
  border-radius: 10px;
  padding: 40px 20px 20px;
  margin-bottom: 20px;
  overflow: hidden;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.info-box i {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.35;
  font-size: 48px;
}
.info-box-count {
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / 1300));
  font-family: 'Inter-Bold';
  margin: 0;
}
.blue-icon {
  color: #1b72bf;
}
.green-icon {
  color: #14b8a6;
}
.orange-icon {
  color: #f68b1f;
}
aside {
  background: #515151;
  padding: 20px;
  border-radius: 10px;
}
aside h4 {
  padding-bottom: 8px;
  border-bottom: 1px solid #e9e9e9;
}
aside canvas {
  margin-bottom: 25px;
}
.filter-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.filter {
  background: #14b8a6;
  padding: 5px 10px;
  border-radius: 5px;
}
.filter + .filter {
  margin-left: 10px;
}
.table {
  /* border: 1px solid #808080; */
  /* background: #565656; */
  color: #ffffff;
  z-index: 10000000;
  pointer-events: all !important;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;


}
.table thead {
  background: #1A1A1A;
  color: #ffffff;
}
.table > :not(:last-child) > :last-child > * {
  border: none;
}
.table > :not(caption) > * > * {
  border-bottom: 1px solid #808080;
  letter-spacing: 1px;
}
td .success {
  display: block;
  width: 100px;
  text-align: center;
  border: 1px solid  #14b8a6;
  padding: 2px 10px;
  border-radius: 15px;
}
td .failed {
  display: block;
  width: 100px;
  text-align: center;
  border: 1px solid #f68b1f;
  padding: 2px 10px;
  border-radius: 15px;
}
.login-bg {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  width: 450px;
  max-width: 100vw;
  text-align: center;
  padding: 60px 15px;
}
img.admin-logo {
  height: 35px;
  width: auto;
  margin-bottom: 30px;
}
.login-form h3 {
  font-family: 'Inter';
  margin-bottom: 25px;
}
.form-group {
  margin-bottom: 20px;
  position: relative;
}
.form-group label {
  margin-bottom: 5px;
  display: block;
  text-align: left;
}
.login-form input {
  height: 45px;
  padding: 0 15px;
  border: 1px solid #c4c4c4;
  color: #212b36;
  border-radius: 5px;
  background-color: #ffffff;
  width: 100%;
  outline: 0;
  -webkit-box-shadow: 0;
  box-shadow: 0;
}
.eye-toggler {
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
}
.login-form p {
  text-align: left;
  margin-bottom: 20px;
  color: #f68b1f;
}
.login-btn {
  background: #43AF01;
  width: 100%;
  height: 55px;
  color: #ffffff;
  font-size: 13px;
  border: 0;
  border-radius: 5px;
  margin-bottom: 30px;
}
.content-box {
  background: #1A1A1A;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  width: 100%;
  margin-left: 10px;
 
}
.content-title {
  display: block;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: 16px;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-weight: bold;
 color: #43AF01;
  /* border-bottom: 1px solid #d2d2d2; */
}
/* .content-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1.5px;
  height: 3px;
  width: 60px;
  background-color: #14b8a6;
} */
.profile tr td {
  padding: 10px 0;
}
.profile tr td:first-of-type {
  font-family: 'Gilroy-ExtraBold';
}
.debit-card {
  background: #14b8a6;
  border-radius: 10px;
  padding: 20px;
}
.debit-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.debit-card h2 {
  margin-top: 25px;
}
.expiry {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
}
.expiry p {
  margin-bottom: 0;
  font-size: 12px;
}
.expiry span {
  font-size: 16px;
}
@media screen and (max-width: 991px) {
  nav {
    display: none;
    position: relative;
    top: 65px;
    left: -230px;
    height: auto;
    width: 230px;
    background: #14b8a6;
    border-radius: 0;
    background-color: black;
  }
  nav.close {
    display: block;
    position: fixed;
    top: 65px;
    left: 0;
    width: 230px;
    z-index: 999;
  }
  .admin-header.shadow:after {
    content: '';
    position: fixed;
    top: 65px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
  }
  .logo {
    display: none;
  }
  nav.close ul {
    padding: 0;
  }
  nav.close ul li {
    border-radius: 0;
    padding: 15px;
  }
  nav.close ul li span {
    display: inline-block;
  }
  .page-container,
  .page-container.close {
    display: block;
    margin-left: 0;
    width: 100vw;
  }
  .admin-header {
    padding: 15px;
  }
  .admin-header input {
    display: none;
  }
  .page-body {
    padding: 15px;
  }
  .content-box {
    padding: 15px;
  }

}

/* loader styles */
.shapes-8 {
  width: 40px;
  height: 40px;
  display: grid;
  animation: sh8-0 1.5s infinite linear;
  margin: 0 auto;
}
.shapes-8:before,
.shapes-8:after {
  content: "";
  grid-area: 1/1;
  background: #43AF01;
  clip-path: polygon(0 0%,100% 0,100% 100%);
  animation: inherit;
  animation-name: sh8-1;
}
.shapes-8:after {
  --s:-1;
}
@keyframes sh8-0 {
  66%      {transform: skewX(0deg)}
  80%,100% {transform: skewX(-45deg)}
}
@keyframes sh8-1 {
  0%   {transform:scale(var(--s,1)) translate(-0.5px,0)}
  33%  {transform:scale(var(--s,1)) translate(calc(1px - 50%),calc(1px - 50%))}
  66%  {transform:scale(var(--s,1)) translate(calc(1px - 50%),0%)}
  100% {transform:scale(var(--s,1)) translate(calc(0.5px - 50%),0%)}
}


.spinner-3 {
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #43AF01;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: s3 1s infinite linear;
}
@keyframes s3 {to{transform: rotate(1turn)}}

label{
  color: #d2d2d2;
}

select{
  color: #d2d2d2;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #fff !important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current{
  color: #fff !important
}

h6{
  font-size: 16px;
}

.css-26l3qy-menu {
  background-color: #202020;
}
 .css-26l3qy-menu:active {background-color: #14b8a6;}

 .ReactModal__Content .ReactModal__Content--after-open{
  transform: translate(87%, -83%) !important;
  background-color: #ffffff;
 }

 .filter {
  position: relative;
  display: flex;
  margin-left: auto;
  align-items: center;
  color: #ffffff;
  background: #43AF01;
  border-radius: 20px
}

 .filter-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background: #515151;
  width: 180px;
  margin-top: 10px;
  z-index: 99;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.03);
}
.filter-dropdown li {
  padding: 10px 15px;
}
.filter-dropdown li i {
  margin-right: 10px;
}
.filter-dropdown li:hover {
  background: #303030;
}

/* Filter */

.navContainer {
  position: absolute;
  display: none;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 16px 2px rgb(33 43 54 / 8%), 0 0 0 1px rgb(6 44 82 / 10%);
  border: 1px solid #d5dae1;
  width: 420px;
  z-index: 2;
  top: 140%;
  right: 10px;

}



.filter-container {
  position: relative;
}

.date-title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  background-color: #f4f6f8;
  padding: 10px 18px;
  width: 99.9%;
  color: #25303a;
}
.custom-filter__item--pad {
  padding: 10px 18px;
}

.custom-filter__item {
  border-bottom: solid 1px #f2f3f7;
}

.overview-filter {
  display: flex;
}

.overview-filter__item__radio {
  display: none;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.overview-filter__item__link {
  display: block;
  color: #637381;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px 16px;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-family: inherit;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.date-content {

    margin-bottom: 10px;

    padding: 5px 18px;

}

.date-items {
  display: flex;
}

.custom-filter__item {
  border-bottom: solid 1px #f2f3f7;
}

.custom-filter__item__content {
  margin-bottom: 10px;
}

.custom-filter__item__content {
  padding: 5px 18px;
}

.checkbox--flex {
  display: flex;
  flex-wrap: wrap;
}

.checkbox {
  margin: 15px
}

.checkbox__item {
  padding-right: 15px;
  margin-bottom: 10px;

}

.full-width {
  width: 100%;
}

.checkbox__input {
  display: none;
}
[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
button, input {
  overflow: visible;
}

.checkbox__label {
  position: relative;
  transition: all 0.3s ease-in-out;
  padding-left: 24px;
}

input {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 10px;
}

label {
  color: #202020;
}

.checkbox__label::before {
  position: absolute;
  content: ' ';
  width: 15px;
  height: 15px;
  left: 0;
  top: 2px;
  border-radius: 3px;
  border: 1px solid #E6E7E9;
  transition: all 0.3s ease-in-out;
}

.checkbox__input:checked + .checkbox__label::before {
  content: '\2713';
  font-size: 13px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  padding-left: 2px;
  font-weight: 700;
  color: #43AF01;
  border-color: #43AF01;
}

.button {
  /*   background: black; */
  color: #ffffff;
  letter-spacing: 1.6px;
  font-size: 16px;
  font-weight: bold;
  display: block;
  padding: 2px 30px;
  border-radius: 9px;
  box-shadow: none;
  background-color: none;
  cursor: pointer;
  text-decoration: none;
  border: 1px dashed #43AF01;
  background: transparent;
  background-position: 100% 0px;
  background-size: 200% 100%;
  transition: 0.7s ease-in-out;
  margin-bottom: 10px;
}

.button p {
  text-decoration: none;
  color: #000000;
}

.staus-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-main p {
  margin-bottom: 10px;
  letter-spacing: 1.4px;

}


.content-main p span {
  padding-left: 20px;
}


.profile-box {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 color: #ffffff;
 margin-bottom: 40px;
}

.profile-content {
  margin-right: 25px;
}

.profile-content span {
  margin-left: 10px;
  letter-spacing: 1.2px;
}

.profile-content svg {
  color: #ba6a18
}

/* .table-width  > * {
  width: 100%;
} */

.investment-form .form-group {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 width: 100%;
 
}

.investment-form .form-group label {
  margin-right: 250px;

font-style: normal;
font-weight: 400;
font-size: 15px;
text-transform: uppercase;
letter-spacing:2px;
width: 30%;

color: #FFFFFF;
}

.investment-form .form-group .invest-input {

padding: 16px;
width: 500px;
background: #1A1A1A;
border: none;
color: #fff;
border-radius: 5px;
width: 60%;
}
.investment-form .form-group .react-datepicker-wrapper {
  width: 60%;
  
}
.investment-form .submit-btns {
color: #fff;
display: flex;
justify-content: flex-start;
align-items: center;
}
.investment-form .submit-btns button{
border: 1px solid #4CC800;
border-radius: 23px;
padding: 8px;
color: #fff;
width: 100px;
margin-right: 30px;
}

tbody  {
 font-size: 13px;
}

.account-officers {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 1.2px;
  color: #4CC800;
  text-transform: uppercase
}

.table-divider {
  margin-bottom: 40px
}

.tab-header{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tab-header li {
  margin-right: 20px;
  cursor: pointer;
}

.tab-header li:active{
  color: greenyellow;
}

.tab-title {
  letter-spacing: 1.2px;
    border: 1px solid green;
    width: fit-content;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px;
}

.tab-body div {
  transition: 2s ease-in-out;
}

.permission-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

 /* .after-line {
  content: "";
  display: block;
  height: 900px;
  width: 1px;
  background-color: #4CC800;
  position: relative;
  top: 60px;
  margin-left: 20px;
} */

/* .flex-item {
  padding: 30px;
  width: 500px;
}

.flex-item ul{
  margin-top: -88px
}
*/
.flex-item ul li {
  margin-bottom: 10px;
  font-size: 13px;
  letter-spacing: 1.1px;
} 

.flex-item ul li::before {
  
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a 
  bullet */
  color: green; /* Change the color */
  font-weight: 
  bold;
  display: inline-block; 

  width: 20px; 
  height: 11px;;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* 
  Also needed for space (tweak if needed) */
}


.flex-title {
  /* margin-top: -394px;
  margin-bottom: 145px; */
  color: #4CC800;
  font-size: 18px;
  letter-spacing: 1.2px;
  text-align: center
}

.permission-body .form-group input{
  background-color: #1A1A1A;
    border: none;
    margin-left: 10px;
    width: 500px;
}

.permission-body .form-group{
  display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.red-list::before {

  color: red; /* Change the color */

}

.btn-style {
  background: #43AF01;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    border-radius: 20px;
    color: #fff;
    margin: 0 auto;
}

.select-fix {
  -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background: none;
    border: none;
    padding: 10px;
    width: auto;
    border-radius: 5px;
    cursor: pointer;
    font-size: 17px;
}

.withdrawal-btn {
  cursor: pointer;
  letter-spacing: 1.1px;
  color: #fff;
  border: 1px dotted green;
  border-radius: 200px;
  margin: 0 auto;
  padding: 10px
}

.withdrawal-red {
  border: 1px dotted red;
}

.header-styled li {
  margin-right: 110px;
  font-size: 15px;
  letter-spacing: 1.2px;
  color: #4CC800;

}

.font-reduce {
  font-size: 12px;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 17px;
}
.placeholder-dropdown {
  background: #1A1A1A;
  color: #ffffff;
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-left: 30px;
  padding: 10px;
  border-radius: 10px;
}

.placeholder-dropdown p {
  width: 100%;
}

/* modal styles */

.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 30%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 450px;
  height: 300px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  display: block !important;
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.area {
  width: 310px;
  height: 114px;
  padding: 5px;
  border-radius: 10px;
  border-color: black;
  text-align: center;
  font-size: 11px;
  letter-spacing: 1.1px;
}

.checkbox-item-styled {
  width: 100%;
  border-radius:10px;
  border: 1px solid grey;
  font-size: 14px;
}

.page-flexed {
 display: grid;
 grid-template-columns: 1fr 500px;
 gap: 20px;
}

.limit-side {
  font-size: 14px;
  font-weight: 400;
}

.limit-side p {
  margin-bottom: 10px;
  letter-spacing: 1.3px;
}

.limit-side span {
  font-size: 12px;
  font: caption;
}

body {
  user-select: none;
 }
div.cc {
 background: linear-gradient(to bottom right,#4CC800,#4CC800) center center fixed no-repeat;
 border: 2px solid black;
 border-radius: 15px;
 width: 350px;
 margin-left: 20px;
 margin-top: 15px;
}
div.mc {
display: flex;
}
div.ctyp {
  display: block;
  float: right;
}
img.logo {
	width: 140px;
  height: 100px;
  margin: -20px 0px 0px 12px;
}
 font.hf {
 	 display: block;
 	 font-family: arial;
   font-size: 15px;
   color: rgba(0,0,0,0.6);
   font-weight: bold;
   margin: 15px 12px 0px 110px;
 }
  div.cno{
  	display: block;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 2px;
    color: black;
    text-align: center;
    /* border: 1px solid black; */
    margin: 5px 0px 10px 0px; 
  }
  div.sci {
    margin: -12.5px 0px 0px 10px;
  }
  img.chip {
  	height: 40px;
    width: 40px;
  }
  div.exp {
  	display: block;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 11px;
    color: black; 
    margin-top: -11px;
    margin-bottom: 7px;
    
  }
  div.name{
  	font-family: 'Open Sans', sans-serif;
    text-align: left;
    color:black;
    letter-spacing: 0.65px;
    font-size:15px;
    font-weight: 600;
    margin: 0px 0px 12.5px 13px; 
  }
  div.nv {
  	display: flex;
  }
  div.visa {
  	float: right;
    margin-left: 125px;
    text-align: right;
  }
  img.vsa {
  	width: 50px;
    height: 17px;
  }
  img.nfci {
  	width: 30px;
    height: 30px;
  }
  div.cn {
  	display: flex;
  }
  div.nfc {
  	margin: -8px 0px 0px 10px;
  }

  .control {
    font-family: system-ui, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    color: white
  }
  
  .control + .control {
    margin-top: .5em;
  }
  
  .control--disabled {
    color: var(--control-disabled);
    cursor: not-allowed;
  }
  
  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;
  
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
  
    /* display: grid;
    place-content: center; */
    padding: 10px;
    margin-bottom: 10px;
  }
  
  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  
  input[type="checkbox"]:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  }
  
  input[type="checkbox"]:disabled {
    --control-color: var(--control-disabled);
  
    color: var(--control-disabled);
    cursor: not-allowed;
  }

  .cus-select {
    background: #1a1a1a;
    color: #ffffff;
    border: none;
    padding: 20px;
    width: 550px;
    border-radius: 10px
  }

  @media screen and (max-width: 1200px) {
    .treegar-investment .form-group {
      display: block;
    }
    .treegar-investment .form-group .invest-input {
      width: 90%;
    }
    .treegar-investment .form-group select {
      width: 90%;
    }
  }

 .investment-form .form-group .overdraft-input {
    width: 400px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto); /* Adjust the height as needed */
    gap: 20px;

  }

  .grid-item {
    background-color: #101010;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #101010;
  }



